import React from "react";
import "./Homepage.css";
import Topsegment from "./Topsegment";
import Bottomsegment from "./Bottomsegment";

export default function Homepage() {
  return (
    <>
        <Topsegment/>
        <Bottomsegment/>

    </>
  );
}
